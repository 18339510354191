<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Argon"
      title="Argon"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-album-2 text-color',
            path: '/dashboard'
          }"
        />
        <sidebar-item :link="{name: 'Blog', icon: 'ni ni-ruler-pencil text-color', path: '/blog'}"/>
        <sidebar-item :link="{name: 'Gestion des mails', icon: 'ni ni-email-83 text-color', path: '/email'}"/>
        <!-- <sidebar-item :link="{name: 'Contact', icon: 'ni ni-email-83 text-color', path: '/'}"/> -->
        <sidebar-item :link="{name: 'Compte utilisateur', icon: 'ni ni-single-02 text-color', path: '/users'}"/>
        <sidebar-item :link="{name: 'Événement', icon: 'ni ni-bell-55 text-color', path: '/events'}"/>
        <sidebar-item :link="{name: 'Gestion des commandes', icon: 'ni ni-bell-55 text-color', path: '/commande'}"/>
        <sidebar-item :link="{name: 'Gestion des articles', icon: 'ni ni-cart text-color', path: '/articles'}"/>
      </template>
      
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
  
      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      }
    }
  };
</script>
<style lang="scss">
.text-color{
  color: #DC0746;
}
</style>
