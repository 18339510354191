import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'blog',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue'),
          beforeEnter: (to, from, next) => {
            if (localStorage.getItem("localStorageT")){
              next()
            }else{
              from()
            }
          }
        },
        // {
        //   path: '/profile',
        //   name: 'profile',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        // },
        {
          path: "/blog",
          name: 'blog',
          component: () => import(/* webpackChunkName: "demo" */ './views/Tables.vue'),
          beforeEnter: (to, from, next) => {
            if (localStorage.getItem("localStorageT")){
              next()
            }else{
              from()
            }
          }
          
        },
        {
          path: "/email",
          name: 'email',
          component: () => import(/* webpackChunkName: "demo" */ './views/email.vue'),
          beforeEnter: (to, from, next) => {
            if (localStorage.getItem("localStorageT")){
              next()
            }else{
              from()
            }
          }
          
        },
        {
          path: "/commande",
          name: 'Commande',
          component: () => import(/* webpackChunkName: "demo" */ './views/tableCommande.vue'),
          beforeEnter: (to, from, next) => {
            if (localStorage.getItem("localStorageT")){
              next()
            }else{
              from()
            }
          }
          
        },
        
        {
          path: "/welcomePage",
          name: 'Email bienvenu',
          component: () => import(/* webpackChunkName: "demo" */ './views/emailWelcome.vue'),
          beforeEnter: (to, from, next) => {
            if (localStorage.getItem("localStorageT")){
              next()
            }else{
              from()
            }
          }
          
        },
        {
          path: "/blogDetails",
          name: 'Email blog',
          component: () => import(/* webpackChunkName: "demo" */ './views/emailBlog.vue'),
          beforeEnter: (to, from, next) => {
            if (localStorage.getItem("localStorageT")){
              next()
            }else{
              from()
            }
          }
          
        },
        {
          path: "/articles",
          name: 'Articles',
          component: () => import(/* webpackChunkName: "demo" */ './views/articles.vue'),
          beforeEnter: (to, from, next) => {
            if (localStorage.getItem("localStorageT")){
              next()
            }else{
              from()
            }
          }
          
        },
        {
          path: "/events",
          name: 'Événement',
          component: () => import(/* webpackChunkName: "demo" */ './views/events.vue'),
          beforeEnter: (to, from, next) => {
            if (localStorage.getItem("localStorageT")){
              next()
            }else{
              from()
            }
          }
          
        },
        {
          path: "/users",
          name: 'Utilisateur',
          component: () => import(/* webpackChunkName: "demo" */ './views/users.vue'),
          beforeEnter: (to, from, next) => {
            if (localStorage.getItem("localStorageT")){
              next()
            }else{
              from()
            }
          }
          
        },
        
        
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue'),
          beforeEnter: (to, from, next) => {
            if (!localStorage.getItem("localStorageT")){
              next()
            }else{
              from()
            }
          }
        },
        // {
        //   path: '/register',
        //   name: 'register',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Register.vue')
        // }
      ]
    }
  ]
})
